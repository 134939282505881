import React, { Component } from "react";
import { filter } from "lodash";
import HometrainingApis from "utils/hometrainingApis";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import * as hometrainingActions from "store/modules/hometraining";
import update from "immutability-helper";
import PopupQueture from "components/PopupQueture/PopupQueture";
import Toast from "components/Toast/Toast";
import * as authActions from "store/modules/auth";
import QuetureList from "components/QuetureList/QuetureList";
import { Skeleton, message } from "antd";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";
import { getSeqText } from "utils/utils";

class HomeworkCard extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      currentHomework: null,
      selectedFile: null,
      solveQuiz: false,
      quizList: [],
      quizTitle: null,
      quizQuetureId: null,
      popupQuetureData: null,
      isLastQuiz: false,
    };
  }

  componentDidMount() {
    const { homeworkData } = this.props;
    this.getCurrentHomework(homeworkData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.homeworkData !== this.props.homeworkData) {
      this.getCurrentHomework(this.props.homeworkData);
    }
  }

  getCurrentHomework(homeworkData) {
    this.setState({
      isLoading: true,
    });

    // let _homeworkData = null;
    // // if (res.length > 1) {
    // //   _homeworkData = { ...res[0] };
    // // } else {
    // //   _homeworkData = { ...res };
    // // }
    let _homeworkData = homeworkData;
    console.log("_homeworkData: ", _homeworkData);
    const studentId = this.props.user.userId;
    let _isLastQuiz = false;
    _homeworkData.items.map((item, index) => {
      if (item.type === "queture") {
        const _quetureId = item.value;
        const _curSection = _homeworkData.lecture.sections.find(
          (s) => s.type === "QUIZ" && s.quetureId === _quetureId
        );
        let _quizListTemp = _curSection && _curSection.quiz;
        const _endQuizSection = _homeworkData.lecture.sections.find(
          (s) => s.type === "ENDQUIZ"
        );
        if (_endQuizSection) {
          _quizListTemp = _endQuizSection.quiz;
        }

        let isDone = true;
        let correctCount = 0;
        console.log("_quizListTemp: ", _quizListTemp);

        let _quizList = [];
        _quizListTemp &&
          _quizListTemp.map((quiz, i) => {
            const index = _quizList.findIndex((q) => q.quizId === quiz.quizId);
            if (index > -1) {
              if (!_quizList[index].solvedMap[studentId]) {
                _quizList[index] = quiz;
              }
            } else {
              _quizList.push(quiz);
            }
          });
        console.log("_quizList: ", _quizList);
        if (_quizList) {
          _quizList.map((quiz) => {
            if (quiz.solvedMap[studentId]) {
              if (
                quiz.solvedMap[studentId].correct === false &&
                !quiz.solvedMap[studentId].retry
              ) {
                console.log(quiz.solvedMap[studentId].retry);
                isDone = false;
              }
              if (quiz.solvedMap[studentId].correct === true) {
                correctCount++;
              }
            } else {
              isDone = false;
            }
          });
          if (correctCount === _quizList.length) {
            _homeworkData["items"][index]["quizStatus"] = "skip";
          } else {
            _homeworkData["items"][index]["quizStatus"] = isDone ? "ok" : "no";
            if (this.context.type === "school") {
              _isLastQuiz = !isDone;
            }
          }
        }
      }
    });
    console.log("_homeworkData:", _homeworkData);

    this.setState({
      isLoading: false,
      isLastQuiz: _isLastQuiz,
      currentHomework: _homeworkData,
    });
  }

  handleEnterSolveQuiz = (quetureData) => {
    const { currentHomework } = this.state;
    console.log("quetureData: ", quetureData);
    const _quetureId = quetureData.quetureId;
    const _curSection = currentHomework.lecture.sections.find(
      (s) => s.type === "QUIZ" && s.quetureId === _quetureId
    );
    const studentId = this.props.user.userId;
    let _quizList = [];
    if (this.context.type === "school") {
      _quizList = _curSection && _curSection.quiz;
    } else {
      _quizList =
        _curSection &&
        _curSection.quiz &&
        filter(_curSection.quiz, function (o) {
          return o.solvedMap[studentId]
            ? o.solvedMap[studentId].correct === false
            : o;
        });
    }
    console.log(_quizList);

    this.setState({
      solveQuiz: true,
      popupQuetureData: null,
      quizList: _quizList,
      quizTitle: quetureData.title,
      quizQuetureId: _quetureId,
    });
  };

  handleSubmitReview = () => {
    const classroomId = this.props.user.classroom.classroomId;
    const lectureId = this.state.currentHomework.lecture.lectureId;
    const quetureId = this.state.popupQuetureData.quetureId;

    if (this.context.type === "school") {
      HometrainingApis.submitReview(classroomId, lectureId, quetureId).then(
        (res) => {
          this.refreshUser();
        }
      );
    }
  };

  handleCompleteQueture = (value) => {
    const { AuthActions } = this.props;
    if (!this.props) {
      return;
    }
    console.log(this.props);
    const classroomId = this.props.user.classroom.classroomId;
    const lectureId = this.state.currentHomework.lecture.lectureId;
    const studentId = this.props.user.userId;
    const homeworkItem = filter(this.state.currentHomework.items, function (o) {
      return o.value === value && o.type === "queture";
    });

    const data = {
      value1: "ok",
    };

    if (
      homeworkItem[0] &&
      homeworkItem[0].myHomework &&
      homeworkItem[0].myHomework.quetureStatus === "ok"
    ) {
      console.log("update");
      return;
    }

    HometrainingApis.submitHomework(
      classroomId,
      lectureId,
      studentId,
      homeworkItem[0].homeworkId,
      data
    ).then((res) => {
      console.log("submit homework: ", res);

      const _homeworkId = homeworkItem[0].homeworkId;

      const _itemIndex = this.state.currentHomework.items.findIndex(
        (i) => Number.parseInt(i.homeworkId) === Number.parseInt(_homeworkId)
      );

      console.log("index: ", _itemIndex);

      this.setState({
        currentHomework: update(this.state.currentHomework, {
          items: {
            [_itemIndex]: {
              $merge: {
                myHomework: res,
              },
            },
          },
        }),
      });
    });
  };

  refreshUser = () => {
    const { user, AuthActions } = this.props;
    const { userId, type, orgId } = user;
    AuthActions.checkCurrentUser(userId, type, orgId);
  };

  onFilesAdded = (files) => {
    console.log("files: ", files);
    const file = files[0];
    this.setState({
      selectedFile: file,
    });
  };

  handleUploadHomework = () => {
    const { selectedFile } = this.state;
    let fileData = new FormData();

    fileData.append(`file`, selectedFile, selectedFile.filename);

    this.setState({
      isLoading: true,
    });
    HometrainingApis.uploadHomwwork(fileData).then((bundleRes) => {
      let bundleId = bundleRes.data.bundleId;
      const classroomId = this.props.user.classroom.classroomId;
      const lectureId = this.state.currentHomework.lecture.lectureId;
      const studentId = this.props.user.userId;
      const homeworkItem = filter(
        this.state.currentHomework.items,
        function (o) {
          return o.type === "upload";
        }
      );

      const data = {
        value1: bundleId,
      };
      console.log("homeworkItem: ", homeworkItem);
      if (homeworkItem && homeworkItem.length > 0) {
        HometrainingApis.submitHomework(
          classroomId,
          lectureId,
          studentId,
          homeworkItem[0].homeworkId,
          data
        )
          .then((res) => {
            console.log("upload homework: ", res);
            this.props.onLoadHomework();
            this.setState({
              selectedFile: null,
            });
          })
          .catch((e) => {
            Toast.warning(
              "학습미션 업로드가 실패되었습니다. 다시 시도해주세요."
            );
            this.setState({
              selectedFile: null,
            });
          });
      }
    });
  };

  handleReuploadHomework = (bundleId) => {
    const { selectedFile } = this.state;
    const { onLoadHomework } = this.props;
    let fileData = new FormData();

    fileData.append(`file`, selectedFile, selectedFile.filename);

    this.setState({
      isLoading: true,
    });

    HometrainingApis.reuploadHomework(bundleId, fileData)
      .then((res) => {
        console.log("reupload homework: ", res);
        this.setState(
          {
            selectedFile: null,
            isLoading: false,
          },
          () => {
            onLoadHomework();
          }
        );
      })
      .catch((e) => {
        Toast.warning("학습미션 업로드가 실패되었습니다. 다시 시도해주세요.");
        this.setState({
          selectedFile: null,
          isLoading: false,
        });
      });
  };

  getEndDate = (str) => {
    if (!str) {
      return;
    }
    const dateArr = str.split("-");
    return `${dateArr[1]}월 ${dateArr[2]}일 까지`;
  };

  render() {
    const { pending, user, lectures } = this.props;
    const {
      currentHomework,
      selectedFile,
      solveQuiz,
      quizList,
      quizTitle,
      popupQuetureData,
      isLoading,
    } = this.state;

    const hasUploadHomework = null;
    // currentHomework &&
    // filter(currentHomework.items, function (o) {
    //   return o.type === "upload";
    // });

    let isPopupQuetureDone = false;
    if (popupQuetureData) {
      const homeworkItem = filter(
        this.state.currentHomework.items,
        function (o) {
          return o.value === popupQuetureData.quetureId;
        }
      );

      if (
        homeworkItem[0] &&
        homeworkItem[0].myHomework &&
        homeworkItem[0].myHomework.quetureStatus === "ok"
      ) {
        console.log("done");
        isPopupQuetureDone = true;
      }
    }
    console.log("currentHomework: ", currentHomework);
    console.log("user: ", this.props.user);
    console.log("hasUploadHomework: ", hasUploadHomework);
    console.log("selected file: ", selectedFile);
    console.log("getLectures: ", lectures);
    console.log("isPopupQuetureDone: ", isPopupQuetureDone);
    console.log("isLastQuiz: ", this.state.isLastQuiz);

    return (
      <Skeleton loading={pending} active>
        <>
          <div className="homework-layout">
            {!currentHomework && (
              <p className="empty-content">&lt;오늘의 과제&gt;가 없습니다. </p>
            )}
            {currentHomework && currentHomework.lecture && (
              <div className="ht-title" style={{ marginBottom: "20px" }}>
                {`${getSeqText(currentHomework.lecture)} 수업 과제`}
                <div className="date-tag">
                  <span>{this.getEndDate(currentHomework.endDate)}</span>
                </div>
              </div>
            )}
            {/*{hasUploadHomework && hasUploadHomework[0] && (*/}
            {/*  // !hasUploadHomework[0].myHomework &&*/}
            {/*  <div className="homework-layout-wrap">*/}
            {/*    <div className="ht-file-upload">*/}
            {/*      <div className="title">*/}
            {/*        학습 미션 업로드*/}
            {/*        {hasUploadHomework[0].myHomework && <span>완료</span>}*/}
            {/*      </div>*/}
            {/*      <p*/}
            {/*        style={{*/}
            {/*          fontSize: 20,*/}
            {/*          marginBottom: 30,*/}
            {/*          marginTop: -10,*/}
            {/*          wordBreak: "break-all",*/}
            {/*        }}*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*          __html: hasUploadHomework[0].value,*/}
            {/*        }}*/}
            {/*      ></p>*/}
            {/*      /!* 파일 업로드 시  : is-upload *!/*/}
            {/*      <div*/}
            {/*        className={classNames("file-upload", {*/}
            {/*          "is-upload": selectedFile,*/}
            {/*        })}*/}
            {/*      >*/}
            {/*        <input*/}
            {/*          type="file"*/}
            {/*          name=""*/}
            {/*          id={*/}
            {/*            currentHomework*/}
            {/*              ? currentHomework.lecture.lectureId*/}
            {/*              : "file"*/}
            {/*          }*/}
            {/*          onChange={(e) => this.onFilesAdded(e.target.files)}*/}
            {/*        />*/}
            {/*        <label*/}
            {/*          htmlFor={*/}
            {/*            currentHomework*/}
            {/*              ? currentHomework.lecture.lectureId*/}
            {/*              : "file"*/}
            {/*          }*/}
            {/*        >*/}
            {/*          {hasUploadHomework[0].myHomework*/}
            {/*            ? hasUploadHomework[0].myHomework.files.items.file.name*/}
            {/*            : "이곳을 클릭해서 학습미션을 업로드해주세요"}*/}
            {/*        </label>*/}
            {/*      </div>*/}
            {/*      {hasUploadHomework[0].myHomework ? (*/}
            {/*        <button*/}
            {/*          className="classmode-btn"*/}
            {/*          onClick={() => {*/}
            {/*            if (isLoading) {*/}
            {/*              return;*/}
            {/*            }*/}
            {/*            if (selectedFile) {*/}
            {/*              this.handleReuploadHomework(*/}
            {/*                hasUploadHomework[0].myHomework.bundleId*/}
            {/*              );*/}
            {/*            } else {*/}
            {/*              Toast.warning("학습미션을 업로드 해주세요");*/}
            {/*            }*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {isLoading ? "업로드 중..." : "수정하기"}*/}
            {/*        </button>*/}
            {/*      ) : (*/}
            {/*          <button*/}
            {/*            className="classmode-btn"*/}
            {/*            onClick={() => {*/}
            {/*              if (isLoading) {*/}
            {/*                return;*/}
            {/*              }*/}
            {/*              if (selectedFile) {*/}
            {/*                this.handleUploadHomework();*/}
            {/*              } else {*/}
            {/*                Toast.warning("학습미션을 업로드 해주세요");*/}
            {/*              }*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            {isLoading ? "업로드 중..." : "제출하기"}*/}
            {/*          </button>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}

            <QuetureList
              onEnterSolveQuiz={(quetureData) =>
                this.props.onEnterSolveQuiz(quetureData, currentHomework)
              }
              lecture={currentHomework && currentHomework.lecture}
              homework={currentHomework && currentHomework.items}
              onShowQueturePopup={(quetureData) => {
                console.log("currentHomework: ", quetureData);
                this.setState({
                  popupQuetureData: quetureData,
                });
              }}
            />
          </div>
          {popupQuetureData && (
            <PopupQueture
              userId={"tester"}
              isHomework={true}
              // onEnterSolveQuiz={() =>
              //   this.handleEnterSolveQuiz(
              //     popupQuetureData,
              //     this.state.currentHomework.lecture.lectureId
              //   )
              // }
              hasQuizList={popupQuetureData.quizStatus !== "skip"}
              quetureData={popupQuetureData}
              isOpen={popupQuetureData !== null}
              isDone={isPopupQuetureDone}
              onReviewed={this.handleSubmitReview}
              onCompleteQueture={() => {
                console.log("popup queture Data: ", popupQuetureData);
                if (isPopupQuetureDone) {
                  console.log("done!");
                  return null;
                }
                this.handleCompleteQueture(popupQuetureData.quetureId);
              }}
              onClose={() => {
                if (
                  popupQuetureData &&
                  popupQuetureData.quetureStatus !== "ok"
                ) {
                  this.props.onLoadHomework();
                }
                this.setState({
                  popupQuetureData: null,
                });
              }}
            />
          )}
        </>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth, hometraining }) => ({
      pending: auth.pending || hometraining.pending,
      user: auth.user,
      logged: auth.logged,
      lectures: hometraining.lectures,
    }),
    (dispatch) => ({
      HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
      AuthActions: bindActionCreators(authActions, dispatch),
    })
  )(HomeworkCard)
);
