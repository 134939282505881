import React, { useState } from "react";
import Popup from "../Popup/Popup";
import QueturePlayer from "../QueturePlayer/QueturePlayer";
import { getHost } from "../../utils/APIUtils";

const PopupQueture = (props) => {
  const {
    isOpen,
    onClose,
    userId,
    quetureData,
    isHomework,
    isDone,
    hasQuizList,
    onCompleteQueture,
    onReviewed,
    onEnterSolveQuiz,
  } = props;

  const [isEnd, setEnd] = useState(false);
  const [isReviewed, setReviewed] = useState(false);

  return (
    <>
      {isOpen && (
        <Popup
          type="quebot-videopopup"
          isOpen={isOpen}
          className="ht-queture-popup"
        >
          <div className="popup-content-wrap">
            <div
              className={`popup-closebtn ${isEnd ? "is-end" : ""}`}
              onClick={() => {
                onClose();
              }}
            ></div>
            <div>
              <QueturePlayer
                apiHost={getHost()}
                userId={userId}
                content={{
                  data: quetureData,
                  type: "main",
                }}
                // disableSeek={
                //   isHomework &&
                //   quetureData &&
                //   quetureData.quetureStatus !== "ok"
                // }
                disableSpeed={isHomework}
                onCompleted={() => {
                  if (onReviewed && !isReviewed) {
                    onReviewed();
                    setReviewed(true);
                  }
                  if (
                    !isDone &&
                    quetureData &&
                    quetureData.quetureStatus !== "ok"
                  ) {
                    onCompleteQueture();
                  }
                }}
                // onEnterSolveQuiz={hasQuizList ? onEnterSolveQuiz : null}
                resolution="auto"
                autoplay
                style={{ outline: "none" }}
                onEnd={() => setEnd(true)}
                onClose={onClose}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default PopupQueture;
