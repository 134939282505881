import React, { Component } from "react";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import "./_homework.scss";
import QuetureList from "../../components/QuetureList/QuetureList";
import HometrainingApis from "../../utils/hometrainingApis";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Skeleton, message } from "antd";
import { filter } from "lodash";
import QuizList from "../../components/QuizList/QuizList";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import * as hometrainingActions from "store/modules/hometraining";
import update from "immutability-helper";
import PopupQueture from "../../components/PopupQueture/PopupQueture";
import Toast from "components/Toast/Toast";
import { ConstantsContext } from "utils/ConstantsContext";
import * as authActions from "store/modules/auth";
import HomeworkCard from "./components/HomeworkCard";

class Homework extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentHomework: null,
      selectedFile: null,
      solveQuiz: false,
      quizList: [],
      quizTitle: null,
      quizQuetureId: null,
      popupQuetureData: null,
      isLastQuiz: false,
      homeworkList: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    HometrainingApis.currentLecture(user)
      .then((isStart) => {
        if (isStart) {
          this.props.history.push(`/classroom/${user.classroom.classroomId}`);
        } else {
          this.getCurrentHomework();
          this.props.HometrainingActions.getLectures();
        }
      })
      .catch((e) => {
        this.getCurrentHomework();
        this.props.HometrainingActions.getLectures();
      });
  }

  getCurrentHomework = () => {
    this.setState({
      isLoading: true,
    });

    HometrainingApis.current(this.context.type === "school")
      .then((res) => {
        console.log("homework data res: ", res[0], res);
        let _homeworkData = null;
        if (res.length > 1) {
          console.log(1);
          _homeworkData = { ...res[0] };
        } else {
          console.log(2);
          _homeworkData = res[0] ? { ...res[0] } : { ...res };
        }
        console.log("_homeworkData: ", _homeworkData);
        const studentId = this.props.user.userId;
        let _isLastQuiz = false;
        _homeworkData.items.map((item, index) => {
          if (item.type === "queture") {
            const _quetureId = item.value;
            const _curSection = _homeworkData.lecture.sections.find(
              (s) => s.type === "QUIZ" && s.quetureId === _quetureId
            );
            let _quizList = _curSection && _curSection.quiz;
            const _endQuizSection = _homeworkData.lecture.sections.find(
              (s) => s.type === "ENDQUIZ"
            );
            if (_endQuizSection) {
              _quizList = _endQuizSection.quiz;
            }

            let isDone = true;
            let correctCount = 0;
            console.log("_endQuizSection: ", _endQuizSection);
            console.log("_quizList: ", _quizList);
            if (_quizList) {
              _quizList.map((quiz) => {
                if (quiz.solvedMap[studentId]) {
                  if (
                    quiz.solvedMap[studentId].correct === false &&
                    !quiz.solvedMap[studentId].retry
                  ) {
                    console.log(quiz.solvedMap[studentId].retry);
                    isDone = false;
                  }
                  if (quiz.solvedMap[studentId].correct === true) {
                    correctCount++;
                  }
                } else {
                  isDone = false;
                }
              });
              if (correctCount === _quizList.length) {
                _homeworkData["items"][index]["quizStatus"] = "skip";
              } else {
                _homeworkData["items"][index]["quizStatus"] = isDone
                  ? "ok"
                  : "no";
                if (this.context.type === "school") {
                  _isLastQuiz = !isDone;
                }
              }
            }
          }
        });
        console.log("_homeworkData:", _homeworkData);

        this.setState({
          isLoading: false,
          homeworkList: res.length && res.length > 0 ? res : [res],
        });
        return;
      })
      .catch((e) => {
        // this.props.history.push("/home");
        this.setState({
          isLoading: false,
        });
      });
  };

  handleEnterSolveQuiz = (quetureData, currentHomework) => {
    // const { currentHomework } = this.state;
    console.log("quetureData: ", quetureData);
    const _quetureId = quetureData.quetureId;
    const _curSection = currentHomework.lecture.sections.find(
      (s) => s.type === "QUIZ" && s.quetureId === _quetureId
    );
    const _endQuizSection = currentHomework.lecture.sections.find(
      (s) => s.type === "ENDQUIZ"
    );

    const studentId = this.props.user.userId;
    let _quizList = [];
    if (this.context.type === "school") {
      _quizList = _curSection && _curSection.quiz;
    } else {
      if (_endQuizSection) {
        _quizList =
          _endQuizSection &&
          _endQuizSection.quiz &&
          filter(_endQuizSection.quiz, function (o) {
            return o.solvedMap[studentId]
              ? o.solvedMap[studentId].correct === false
              : o;
          });
      } else {
        _quizList =
          _curSection &&
          _curSection.quiz &&
          filter(_curSection.quiz, function (o) {
            return o.solvedMap[studentId]
              ? o.solvedMap[studentId].correct === false
              : o;
          });
      }
    }
    console.log(_quizList);

    this.setState({
      solveQuiz: true,
      popupQuetureData: null,
      quizList: _quizList,
      quizTitle: quetureData.title,
      quizQuetureId: _quetureId,
      currentHomework: currentHomework,
    });
  };

  refreshUser = () => {
    const { user, AuthActions } = this.props;
    const { userId, type, orgId } = user;
    AuthActions.checkCurrentUser(userId, type, orgId);
  };

  render() {
    const { pending, user, lectures } = this.props;
    const {
      currentHomework,
      selectedFile,
      solveQuiz,
      quizList,
      quizTitle,
      popupQuetureData,
      isLoading,
      homeworkList,
    } = this.state;
    const hasUploadHomework =
      currentHomework &&
      filter(currentHomework.items, function (o) {
        return o.type === "upload";
      });

    let isPopupQuetureDone = false;
    if (popupQuetureData) {
      const homeworkItem = filter(
        this.state.currentHomework.items,
        function (o) {
          return o.value === popupQuetureData.quetureId;
        }
      );

      if (
        homeworkItem[0] &&
        homeworkItem[0].myHomework &&
        homeworkItem[0].myHomework.quetureStatus === "ok"
      ) {
        console.log("done");
        isPopupQuetureDone = true;
      }
    }
    console.log("currentHomework: ", currentHomework);
    console.log("user: ", this.props.user);
    console.log("hasUploadHomework: ", hasUploadHomework);
    console.log("selected file: ", selectedFile);
    console.log("getLectures: ", lectures);
    console.log("isPopupQuetureDone: ", isPopupQuetureDone);
    console.log("isLastQuiz: ", this.state.isLastQuiz);

    const hasEndQuizSection =
      currentHomework?.lecture?.sections &&
      filter(currentHomework?.lecture?.sections, function (o) {
        return o.type === "ENDQUIZ";
      });

    return (
      <Skeleton loading={pending} active>
        {solveQuiz ? (
          <QuizList
            isHomework={true}
            isSolveEndQuiz={hasEndQuizSection}
            userId={this.props.user.userId}
            classroomId={this.props.user.classroom.classroomId}
            lectureId={this.state.currentHomework.lecture.lectureId}
            quetureId={this.state.quizQuetureId}
            quizList={quizList}
            title={quizTitle}
            onRefreshUser={this.refreshUser}
            onBack={(isSolvedAll) => {
              this.setState(
                {
                  solveQuiz: false,
                },
                () => {
                  if (isSolvedAll && this.state.isLastQuiz) {
                    this.props.history.push("/review");
                  } else {
                    this.getCurrentHomework();
                    this.refreshUser();
                  }
                }
              );
            }}
          />
        ) : (
          <>
            <HomeHeader lectures={lectures} />
            {this.state.homeworkList.length === 0 && (
              <div className="review-layout">
                <p className="empty-content">
                  &lt;오늘의 과제&gt;가 없습니다.{" "}
                </p>
              </div>
            )}
            {this.state.homeworkList.map((h, index) => (
              <HomeworkCard
                homeworkData={h}
                onLoadHomework={this.getCurrentHomework}
                onEnterSolveQuiz={this.handleEnterSolveQuiz}
              />
            ))}
          </>
        )}
      </Skeleton>
    );
  }
}
export default withRouter(
  connect(
    ({ auth, hometraining }) => ({
      pending: auth.pending || hometraining.pending,
      user: auth.user,
      logged: auth.logged,
      lectures: hometraining.lectures,
    }),
    (dispatch) => ({
      HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
      AuthActions: bindActionCreators(authActions, dispatch),
    })
  )(Homework)
);
